import { IonAvatar, IonCard, IonCol, IonDatetime, IonImg, IonLabel, IonRow } from '@ionic/react'
import { firestore } from 'firebase'

import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import './Messages.css'

// interface Props {
// 	message: {
// 		user?: string
// 		date: Date
// 		msg: string
// 		imgurl: string
// 	}
// }

const Message = ({ message }: any) => {
	let box = <></>
	const [user, loadingUser, errorUser] = useDocument(
		firestore().collection('LineUsers').doc(message.userId)
	)
	useEffect(() => {
		if (errorUser) {
			console.log(errorUser)
		}
	}, [loadingUser, errorUser])

	const newMessage = {
		user: user?.get('displayName') || `${message.userId.substring(0, 8)}`,
		date: new Date(message.time) || null,
		msg: message.text || '',
		imgurl:
			user?.get('pictureUrl') ||
			'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
	}

	switch (message.type) {
		case 'text':
			box = <p>{newMessage.msg}</p>

			break
		case 'sticker':
			//https://stickershop.line-scdn.net/stickershop/v1/sticker/52002737/iPhone/sticker@2x.png
			//https://stickershop.line-scdn.net/stickershop/v1/sticker/407279220/iPhone/sticker_animation@2x.png;

			box =
				message.stickerResourceType == 'ANIMATION' ? (
					<IonImg
						id='gif'
						onClick={() => {
							const doc = document.getElementById('gif')! as HTMLIonImgElement
							doc.src = `https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.stickerId}/android/sticker_animation.png;compress=false`
						}}
						src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.stickerId}/android/sticker_animation.png;compress=true`}
					/>
				) : (
					<IonImg
						src={`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.stickerId}/android/sticker.png;compress=true`}
					/>
				)
			break
		case 'image':
			if (!message.content) {
				box = <p>{newMessage.msg ? newMessage.msg : 'type: file err: loading file'}</p>
				break
			}
			box = <IonImg src={`data:*;base64,${message.content}`} />
			break
		case 'file':
			if (!message.content) {
				box = <p>{newMessage.msg ? newMessage.msg : 'type: file err: loading file'}</p>
				break
			}

			box = (
				<p>
					<a href={`data:*;base64,${message.content}`} download={message.fileName}>
						{message.fileName}
					</a>{' '}
				</p>
			)

			break
		default:
			break
	}

	if (newMessage.user === 'me') {
		return (
			<IonRow>
				<IonCol size='2' sizeMd='1'>
					<IonDatetime
						class='time'
						displayFormat='HH:mm'
						value={newMessage.date.toString()}
						disabled></IonDatetime>
				</IonCol>
				<IonCol size='10' sizeMd='11'>
					<br />
					{/* <div style={{ width: '100%' }}> */}
					<IonCard class='text-box-right'>{box}</IonCard>
				</IonCol>
				{/* </div> */}
			</IonRow>
		)
	}
	return (
		<IonRow>
			<IonCol size='12'></IonCol>
			<IonCol size='2' sizeMd='1'>
				<IonAvatar class='item-avatar'>
					<img src={newMessage.imgurl} alt=''></img>
				</IonAvatar>
			</IonCol>
			<IonCol size='8' sizeMd='10'>
				<IonLabel class='text-label'>{newMessage.user}</IonLabel>
				<br />
				{/* <div style={{ width: '100%' }}> */}
				<IonCard class='text-box-left'>{box}</IonCard>
			</IonCol>
			<IonCol size='2' sizeMd='1'>
				<IonDatetime
					class='time'
					displayFormat='HH:mm'
					value={newMessage.date.toString()}
					disabled></IonDatetime>
			</IonCol>
		</IonRow>
	)
}

export default Message
