import { IonAvatar, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { firestore } from 'firebase'

import React, { useState } from 'react'
import * as icon from 'ionicons/icons'
// import './Rooms.css'

// interface Props {
// 	room: {
// 		name: string
// 		text: string
// 		date: Date
// 		imgurl: string
// 	}
// }

const Room = ({ room }: any) => {
	const roomstring = room.conId
	const [name, setName] = useState<string>(`${room.type}: ${roomstring.substring(0, 8)}`)
	const [imgurl, setImgurl] = useState<string>(
		'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
	)

	if (room.type === 'user') {
		firestore()
			.collection('LineUsers')
			.doc(room.conId)
			.get()
			.then((doc) => {
				if (doc) {
					setImgurl(doc.get('pictureUrl'))
					setName(doc.get('displayName'))
				}
			})
	}

	return (
		<IonItem button routerLink={`/tab/2/${room.conId}`}>
			<IonAvatar slot='start'>
				<img src={imgurl} alt='' />
			</IonAvatar>
			<IonLabel>
				{room.unRead === 1 && (
					<IonIcon
						style={{ color: '#dd6666', float: 'right' }}
						slot='icon-only'
						icon={icon.alertCircle}
						size='small'
					/>
				)}
				<h2>{name} </h2>
				{/* <h3>{room.lastMessage}</h3> */}
				<p>{room.lastMessage}</p>
			</IonLabel>
		</IonItem>
	)
}

export default Room
