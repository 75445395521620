import React, { useState } from 'react'
import {
	IonButton,
	IonInput,
	IonItem,
	IonList,
	IonListHeader,
	IonPopover,
	IonTitle,
} from '@ionic/react'
import { firestore } from 'firebase'
import { Plugins } from '@capacitor/core'

const { Storage } = Plugins

interface Props {
	popover: {
		showPopover: boolean
		setShowPopover: (base: boolean) => void
	}
}

const Adminmenu: React.FC<Props> = ({ popover }) => {
	const [clientId, setClientId] = useState('')
	const [clientSecret, setClientSecret] = useState('')

	const setLine = async () => {
		popover.setShowPopover(false)
		setClientId('')
		setClientSecret('')
		Storage.get({ key: 'key' }).then((data) => {
			if (data.value) {
				const e = JSON.parse(data.value)
				firestore()
					.collection('Members')
					.doc(e.id ? e.id : 'a')
					.get()
					.then((doc) => {
						if (doc.get('active') && doc.get('admin')) {
							firestore().collection('Customers').doc(doc.get('customerId')).set(
								{
									lineClientId: clientId,
									lineClientSecret: clientSecret,
								},
								{ merge: true }
							)
						}
					})
			}
		})
	}

	return (
		<IonPopover
			isOpen={popover.showPopover}
			cssClass='my-custom-class'
			onDidDismiss={(e) => popover.setShowPopover(false)}>
			<IonList>
				<IonListHeader>
					<IonTitle>Admin Settings</IonTitle>
				</IonListHeader>
				{/* <IonItem
					href={`${process.env.REACT_APP_uri}/LineInitFollowers`}
					target='_blank'>
					Sync Line Friends
				</IonItem> */}
				<IonListHeader>Line Credisentials</IonListHeader>
				{/* <IonItem>
					<IonInput
						value={clientId}
						placeholder='Client Id'
						onIonChange={(e) => setClientId(e.detail.value!)}></IonInput>
				</IonItem>
				<IonItem>
					<IonInput
						value={clientSecret}
						placeholder='Client Secret'
						onIonChange={(e) => setClientSecret(e.detail.value!)}></IonInput>
				</IonItem> */}
				<IonItem lines='none'>
					<IonButton class='button' expand='block' onClick={setLine}>
						Save
					</IonButton>
				</IonItem>
			</IonList>
		</IonPopover>
	)
}

export default Adminmenu
