import { IonCol, IonFooter, IonInput, IonRow, IonToolbar, IonIcon, IonButton } from '@ionic/react'

import React, { useRef, useState } from 'react'
import * as icon from 'ionicons/icons'
import './Chatbar.css'
import { ImportsNotUsedAsValues } from 'typescript'
import { lookup } from 'mime-types'
import { read } from 'fs'

interface TypeFile {
	file: any
}

function getBase64(file: any) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onloadend = () => {
			try {
				let result: string = reader.result as string
				resolve({
					type: (lookup(file.name) as string).split('/')[0],
					file: result.replace('data:', '').replace(/^.+,/, ''),
					name: file.name,
				})
			} catch (err) {
				console.log(err)
			}
		}
		reader.onerror = (error) => reject(error)
	})
}

const Chatbar = ({ sendMessage }: any) => {
	const [text, setText] = useState('')
	const [filename, setFilename] = useState('')
	const file = useRef<TypeFile>({
		file: null,
	})

	return (
		<IonFooter class='footer'>
			<IonToolbar class='toolbar' color='dynamicColor'>
				<IonRow>
					<IonCol class='item'>
						<IonInput
							class='input'
							value={text}
							placeholder='Aa'
							onIonChange={(e) => setText(e.detail.value!)}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									if (text.replace(/\s/g, '').length) {
										sendMessage({
											type: 'text',
											text: text,
										})
									}
									setText('')
									if (file.current.file) {
										getBase64(file.current.file)
											.then((res: any) =>
												sendMessage({
													type: res.type == 'image' ? 'image' : 'file',
													content: res.file,
													fileName: res.name,
												})
											)
											.catch(console.log)
										// ;(document.getElementById('upload')! as HTMLInputElement).value = ''
										setFilename('')
										file.current.file = null
									}
								}
							}}
						/>
					</IonCol>
					<IonCol class='item button'>
						{filename ? (
							<IonButton class='button' color='danger' fill='clear' onClick={() => setFilename('')}>
								<IonIcon icon={icon.attachOutline} size='large' />
							</IonButton>
						) : (
							<IonButton
								class='button'
								fill='clear'
								onClick={() => (document.getElementById('upload')! as HTMLInputElement).click()}>
								<input
									accept='*'
									id='upload'
									hidden
									type='file'
									onChange={(event) => {
										if (event.target.files && event.target.files[0]) {
											file.current.file = event.target.files[0]
											setFilename(event.target.files[0].name)
										} else {
											setFilename('')
											file.current.file = null
										}
									}}
								/>
								<IonIcon slot='icon-only' icon={icon.addOutline} size='large' />
							</IonButton>
						)}
					</IonCol>

					<IonCol class='item button'>
						<IonButton
							class='button'
							fill='clear'
							onClick={(event) => {
								if (text.replace(/\s/g, '').length) {
									sendMessage({
										type: 'text',
										text: text,
									})
								}
								setText('')
								if (file.current.file) {
									getBase64(file.current.file)
										.then((res: any) =>
											sendMessage({
												type: res.type == 'image' ? 'image' : 'file',
												content: res.file,
												fileName: res.name,
											})
										)
										.catch(console.log)
									// ;(document.getElementById('upload')! as HTMLInputElement).value = ''
									setFilename('')
									file.current.file = null
								}
							}}>
							<IonIcon slot='icon-only' icon={icon.sendOutline} size='large' />
						</IonButton>
					</IonCol>
				</IonRow>
			</IonToolbar>
		</IonFooter>
	)
}

export default Chatbar
