import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	IonAvatar,
	IonButton,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonItem,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import Messages from '../components/Messages'
import Chatmenu from '../components/Chatmenu'
import Chatbar from '../components/Chatbar'

import * as icon from 'ionicons/icons'

import './Tab2.css'
import { firestore } from 'firebase'
import { useCollection, useDocumentOnce } from 'react-firebase-hooks/firestore'
import Axios from 'axios'

const zohoDefaultUrl = `https://crm.zoho.com/crm/${process.env.REACT_APP_org}/tab/`

const Tab2: React.FC = () => {
	const params: any = useParams()
	const id = params.conId
	const [zohoId, setZohoId] = useState('')
	const [type, setType] = useState('')
	const [showPopover, setShowPopover] = useState(false)
	// const [userList, setuserList] = useState({})
	const [header, setHeader] = useState({
		imgurl: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
		name: '',
	})
	const [room, loadingroom, errorroom] = useDocumentOnce(
		firestore().collection('LineConversations').doc(id)
	)
	const [value, loading, error] = useCollection(
		// firestore().collection('LineMessages').where('conId', '==', id),
		firestore()
			.collection('LineMessages')
			.where('conId', '==', id)
			.orderBy('time', 'asc')
			.limit(100),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	)

	const scrollToBottom = (query = 'ion-content') => {
		const list: NodeListOf<HTMLIonContentElement> = document.querySelectorAll(query)
		if (list.length) {
			const content: HTMLIonContentElement = list[list.length - 1]
			content.scrollToBottom()
		}
	}

	useEffect(() => {
		scrollToBottom()
		setTimeout(() => {
			scrollToBottom()
		}, 500)

		if (!loadingroom) {
			if (room?.exists) {
				room.ref.set({ unRead: 0 }, { merge: true })
			}
		}

		if (value) {
			const docs = value.docs[value.docs.length - 1]
			if (docs) {
				const data = docs.data()
				console.log('---------------')
				console.log('message:', data.text)
				console.log(data.time)
				console.log(`webhook in: ${data.created - data.time}ms`)
				// console.log(`database write: ${data.created - data.recived}ms`)
				console.log(`database: ${Date.now() - data.created}ms`)
				console.log(`total: ${Date.now() - data.time}ms`)
			}
		}
	}, [value])

	useEffect(() => {
		if (error) {
			console.log(error)
		}
		if (errorroom) {
			console.log(errorroom)
		}
	}, [errorroom, error])

	useEffect(() => {
		// setuserList({})
		if (!loadingroom) {
			if (room?.exists) {
				room.ref.set({ unRead: 0 }, { merge: true })
				const data = room.data()
				if (data?.type === 'user') {
					firestore()
						.collection('LineUsers')
						.doc(id)
						.get()
						.then((user) => {
							setHeader({
								imgurl: user.get('pictureUrl'),
								name: user.get('displayName'),
							})
							setZohoId(user.get('zohoId'))
							setType(user.get('type'))
						})
				} else {
					setHeader({
						imgurl: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
						name: `${data?.type}: ${id.substring(0, 8)}`,
					})
				}
				// data?.users.map((id: string) => {
				// 	const list: { [userId: string]: object } = {}
				// 	firestore()
				// 		.collection('LineUsers')
				// 		.doc(id)
				// 		.get()
				// 		.then((user) => {
				// 			const data = user.data() || {}
				// 			list[id] = data
				// 			setuserList(list)
				// 		})
				// })
			} else {
				//boot to home
			}
		}
	}, [id, room, loadingroom])

	function sendMessage(message: any) {
		const messageEvent = {
			type: 'message',
			timestamp: Date.now(),
			source: {
				type: room?.get('type'),
				conId: room?.get('conId'),
				userId: 'me',
			},
			message: {
				...message,
				contentProvider: {
					type: 'me',
				},
			},
		}

		console.log(messageEvent)

		Axios.post(
			`${process.env.REACT_APP_uri}/LineSendMessage`,

			messageEvent
		)
	}

	return (
		<IonPage>
			{zohoId !== '' && (
				<Chatmenu
					popover={{ showPopover, setShowPopover }}
					zohoUrl={zohoDefaultUrl + type + '/' + zohoId}
				/>
			)}
			<IonHeader>
				<IonToolbar>
					<IonItem lines='none'>
						<IonButton style={{ padding: 0 }} fill='clear' routerLink={`/tab/1`}>
							<IonIcon slot='icon-only' icon={icon.chevronBackOutline} size='large' />
						</IonButton>
						<IonAvatar>
							<img src={header.imgurl} alt='' />
						</IonAvatar>
						<IonTitle>{header.name}</IonTitle>
						{zohoId !== '' && (
							<IonButton
								class='button'
								fill='clear'
								color='medium'
								expand='block'
								slot='end'
								onClick={() => setShowPopover(true)}>
								<IonIcon slot='icon-only' icon={icon.link} size='large' />
							</IonButton>
						)}
					</IonItem>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonGrid>
					{value &&
						value?.docs.map(
							(message, index) =>
								!loading && (
									<Messages message={message.data()} key={index}></Messages>
									//									<Messages message={(message.data(), userList)}></Messages>
								)
						)}
				</IonGrid>
			</IonContent>
			<Chatbar sendMessage={sendMessage} />
		</IonPage>
	)
}

export default Tab2
