import React, { useEffect, useState } from 'react'
import { firestore } from 'firebase'
import { useHistory } from 'react-router-dom'
import {
	IonButton,
	IonCard,
	IonContent,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonPopover,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react'
import './Landing.css'
import Auth from '../Auth'
import Notif from '../Notif'
import * as icon from 'ionicons/icons'
import crypto from 'crypto'
import { Plugins } from '@capacitor/core'
import { useDocument } from 'react-firebase-hooks/firestore'

const { Storage } = Plugins

const Landing: React.FC = () => {
	const [showToast, setShowToast] = useState(false)
	const [showpopover, setshowpopover] = useState(true)
	const history = useHistory()
	const [email, setEmail] = useState<string>('')
	const [id, setId] = useState<string>('')
	const [members, loadingMembers, errorMembers] = useDocument(
		firestore()
			.collection('Members')
			.doc(id ? id : 'a')
	)

	const login = async () => {
		const emailstring = email.replace(/\s/g, '')
		const query = await firestore().collection('Members').where('email', '==', emailstring).get()

		if (query.size > 0) {
			if (query.docs[0].get('active')) {
				const hash = crypto.randomBytes(6).toString('hex')
				setId(query.docs[0].id)
				Storage.set({
					key: 'key',
					value: JSON.stringify({
						id: query.docs[0].id,
						hash: hash,
					}),
				})
				Notif.register((res: string, token: string) => {
					if (res === 'success') {
						window.open(
							`https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.leads.ALL,ZohoCRM.modules.contacts.ALL&client_id=${process.env.REACT_APP_clientid}&response_type=code&access_type=offline&redirect_uri=${process.env.REACT_APP_uri}/loginCustomerZohoCrm&state=${query.docs[0].id}--${hash}--${token}`
						)
					} else {
						alert('Error on registration: ' + JSON.stringify(token))
						window.open(
							`${process.env.REACT_APP_uri}/loginCustomerZohoCrm&state=${query.docs[0].id}--${hash}`
						)
					}
				})
			} else {
				setShowToast(true)
				console.log('account not acctivated')
			}
		} else {
			console.log('no email exist')
			setShowToast(true)
		}
	}

	useEffect(() => {
		Storage.get({ key: 'key' }).then((data) => {
			if (data.value) {
				const e = JSON.parse(data.value)
				setId(e.id)
			}
		})
	}, [])

	useEffect(() => {
		if (!Auth.isAuthenticated()) {
			Auth.login(() => {
				setshowpopover(false)
				history.push('/tab/1')
			})
		} else {
			setshowpopover(false)
			history.push('/tab/1')
		}
	}, [history, members, loadingMembers, errorMembers])

	return (
		<IonPage>
			<IonContent fullscreen>
				<IonPopover isOpen={showpopover} backdropDismiss={false}>
					<IonToolbar>
						<IonTitle size='large'>Login</IonTitle>
					</IonToolbar>
					<IonCard>
						<IonItem>
							<IonLabel position='floating'>Email</IonLabel>
							<IonInput
								value={email}
								onIonChange={(e) => setEmail(e.detail.value!)}
								onKeyPress={(event) => {
									if (event.key === 'Enter') {
										login()
										// history.push(`/Tab1/${search}`)
									}
								}}></IonInput>
						</IonItem>
					</IonCard>
					<IonButton class='button' expand='block' onClick={login}>
						<IonIcon slot='icon-only' icon={icon.logInOutline} size='large' />
					</IonButton>
				</IonPopover>
				<IonToast
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message='The email you entered does not match any account.'
					duration={2000}
				/>
			</IonContent>
		</IonPage>
	)
}

export default Landing
