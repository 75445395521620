import React, { useEffect, useState } from 'react'
import { firestore } from 'firebase'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useHistory } from 'react-router-dom'
import {
	IonAvatar,
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonList,
	IonListHeader,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import auth from '../Auth'
import './Tab1.css'
import Rooms from '../components/Rooms'
import * as icon from 'ionicons/icons'

import { Plugins } from '@capacitor/core'
import Adminmenu from '../components/Adminmenu'
const { Storage } = Plugins

const Tab1: React.FC = (room: any) => {
	const history = useHistory()
	const [search, setSearch] = useState<string>('')
	const [sign, setSign] = useState<any>('==')
	const [zohoOwnerId, setzohoOwnerId] = useState<string>('a')
	const [showPopover, setShowPopover] = useState(false)
	const [userobj, setUserobj] = useState<any>({
		imgurl: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
		username: 'please input key',
	})
	const [value, loading, error] = useCollection(
		firestore().collection('LineConversations').where('zohoOwnerId', sign, zohoOwnerId),
		// .orderBy('zohoOwnerId')
		// .orderBy('last', 'desc'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	)
	useEffect(() => {
		if (error) {
			console.log(error)
		}
	}, [error])

	useEffect(() => {
		if (!auth.isAuthenticated()) {
			history.push('/')
		}
	}, [history])

	useEffect(() => {
		setShowPopover(false)
		let mounted = true
		Storage.get({ key: 'key' }).then((data) => {
			if (data.value) {
				const e = JSON.parse(data.value)
				firestore()
					.collection('Members')
					.doc(e.id ? e.id : 'a')
					.get()
					.then((doc) => {
						if (doc.get('active') && mounted) {
							setzohoOwnerId(e.id ? e.id : '')
							setUserobj({
								imgurl:
									'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
								username: doc.get('fullName'),
							})
							if (doc.get('admin')) {
								setSign('!=')
								setzohoOwnerId('a')
							}
						} else if (mounted) {
							setzohoOwnerId('a')
						}
					})
			}
		})

		return () => {
			mounted = false
		}
	}, [])

	return (
		<IonPage>
			<Adminmenu popover={{ showPopover, setShowPopover }} />
			<IonHeader>
				<IonToolbar>
					<IonItem lines='none'>
						<IonAvatar>
							<img src={userobj.imgurl} alt='' />
						</IonAvatar>
						<IonTitle>{userobj.username}</IonTitle>
						{sign === '!=' && (
							<IonButton
								class='button'
								fill='clear'
								color='medium'
								expand='block'
								slot='end'
								onClick={() => setShowPopover(true)}>
								<IonIcon slot='icon-only' icon={icon.settingsOutline} size='large' />
							</IonButton>
						)}
					</IonItem>
				</IonToolbar>
				<IonToolbar class='searchbar' color='dynamicColor'>
					<IonRow>
						<IonCol size='12'>
							<IonInput
								class='search'
								value={search}
								placeholder='Search'
								onIonChange={(e) => setSearch(e.detail.value!)}
								onKeyPress={(event) => {
									// if (event.key === 'Enter') {
									// 	history.push(`/Tab1/${search}`)
									// }
								}}>
								<IonIcon
									style={{ padding: 4, color: 'DARKGRAY' }}
									slot='start'
									icon={icon.search}
								/>
							</IonInput>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<IonList>
					<IonListHeader>Recent Conversations</IonListHeader>
					{value &&
						value.docs.map(
							(room) => !loading && <Rooms room={room.data()} key={room.get('conId')}></Rooms>
						)}
				</IonList>
			</IonContent>
		</IonPage>
	)
}

export default Tab1
