// import { firestore } from 'firebase/app'
import { Plugins } from '@capacitor/core'
import { Capacitor } from '@capacitor/core'

// const { Storage } = Plugins
const { PushNotifications } = Plugins

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')

class Notif {
	// constructor() {
	// 	this.notifications = [
	// 		{ id: 'id', title: 'Test Push', body: 'This is my first push notification' },
	// 	]
	// }

	register(cb) {
		if (isPushNotificationsAvailable) {
			// Register with Apple / Google to receive push via APNS/FCM
			PushNotifications.register()

			// On succcess, we should be able to receive notifications
			PushNotifications.addListener('registration', (token) => {
				// alert('Push registration success, token: ' + token.value)
				cb('success', token.value)
			})

			// Some issue with your setup and push will not work
			PushNotifications.addListener('registrationError', (error) => {
				// alert('Error on registration: ' + JSON.stringify(error))
				cb('err', error)
			})
		} else {
			cb('success', 'no push notification function on this browser')
		}
	}

	addListener() {
		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener('pushNotificationReceived', (notification) => {
			let notif = this.notifications
			notif.push({
				id: notification.id,
				title: notification.title ?? '',
				body: notification.body ?? '',
			})
			alert(notification.notification.title + notification.notification.body)
			// this.notifications = notif
		})

		// Method called when tapping on a notification
		PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
			let notif = this.notifications
			notif.push({
				id: notification.notification.data.id,
				title: notification.notification.data.title,
				body: notification.notification.data.body,
			})
			alert(notification.notification.data.title + notification.notification.data.body)
			// this.notifications = notif
		})
	}
}

export default new Notif()
