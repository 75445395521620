import { firestore } from 'firebase/app'
import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

class Auth {
	constructor() {
		this.authenticated = false
	}

	login(cb) {
		Storage.get({ key: 'key' })
			.then((res) => {
				const data = res.value ? JSON.parse(res.value) : {}
				if (data.id) {
					firestore()
						.collection('Members')
						.doc(data.id)
						.get()
						.then((doc) => {
							if (doc.get('hash') === data.hash) {
								this.authenticated = true
								cb()
							}
						})
						.catch((err) => console.log(err))
				}
			})
			.catch((err) => console.log(err))
	}

	logout(cb) {
		this.authenticated = false
		cb()
	}

	isAuthenticated() {
		return this.authenticated
	}
}

export default new Auth()
