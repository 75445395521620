import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Tab1 from './pages/Tab1'
import Tab2 from './pages/Tab2'
import Landing from './pages/Landing'
import Notif from './Notif'
// import assign from './components/Assign'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import firebase from 'firebase/app'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	databaseURL: process.env.REACT_APP_databaseURL,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
	measurementId: process.env.REACT_APP_measurementId,
}

firebase.initializeApp(firebaseConfig)

const App: React.FC = () => {
	// let room = ''

	useEffect(() => {
		Notif.addListener()
		firebase
			.firestore()
			.clearPersistence()
			.catch(function (err) {
				console.log('whops')
			})
		firebase
			.firestore()
			.enablePersistence()
			.catch(function (err) {
				console.log('whopss')
			})
	}, [])

	return (
		<IonApp>
			<IonReactRouter>
				<IonRouterOutlet>
					<Switch>
						<Route path='/' component={Landing} exact={true} />
						<Route
							path='/tab'
							render={() => (
								// <IonTabs>
								<IonRouterOutlet>
									<Route path='/tab/1' component={Tab1} exact={true} />
									<Route path='/tab/2/:conId/' component={Tab2} />
									<Route path='/tab/2' render={() => <Redirect to='/tab/1' />} exact={true} />
									<Route path='/tab' render={() => <Redirect to='/tab/1' />} exact={true} />
								</IonRouterOutlet>
								// 	<IonTabBar slot='bottom'>
								// 		<IonTabButton tab='tab1' href='/tab/1'>
								// 			<IonIcon icon={icon.list} />
								// 			<IonLabel>Rooms</IonLabel>
								// 		</IonTabButton>

								// 		<IonTabButton tab='tab2' href={`/tab/2/${room}`}>
								// 			<IonIcon icon={icon.chatbubbles} />
								// 			<IonLabel>Chat</IonLabel>
								// 		</IonTabButton>
								// 	</IonTabBar>
								// </IonTabs>
							)}
						/>
						<Route path='*' render={() => <Redirect to='/' />} />
						{/* <Route path='/assign/:zohoOwnerId/' component={assign} /> */}
					</Switch>
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	)
}

export default App
