import { IonItem, IonList, IonPopover } from '@ionic/react'

import React from 'react'
import './Chatmeun.css'

interface Props {
	popover: {
		showPopover: boolean
		setShowPopover: (base: boolean) => void
	}
	zohoUrl: string
}

const Chatmenu: React.FC<Props> = ({ popover, zohoUrl }) => {
	return (
		<IonPopover
			isOpen={popover.showPopover}
			cssClass='my-custom-class'
			onDidDismiss={(e) => popover.setShowPopover(false)}>
			<IonList>
				{/* <IonListHeader>Zoho actions</IonListHeader> */}
				<IonItem href={zohoUrl} target='_blank'>
					Open Zoho Profile
				</IonItem>
				{/* <IonItem button>Add to existing Lead</IonItem> */}
				{/* <IonItem lines='none' detail='false' button onClick='dismissPopover()'>
				</IonItem> */}
			</IonList>
		</IonPopover>
	)
}

export default Chatmenu
